<ng-container *ngIf='fileFormControl'>
  <input #fileUpload class='display--none' type='file' [formControl]='fileControl()' (change)="onFileChanged($event)" />
  <button mat-flat-button color='{{ buttonType }}' (click)="fileUpload.click()">{{ buttonLabel }}</button>
  <div class='row no-gap justify-items-center mt--sm' *ngIf='uploadedFile && uploadedFile.name'>
    <div class='column--12 pb--none'>
      <span class='text--sm'>{{ uploadedFile.name }}</span>
      <button mat-icon-button class='mat-inline-icon text--danger' (click)='removeFile()'><mat-icon>close</mat-icon></button>
    </div>
  </div>
</ng-container>
