import { Component, Inject, OnInit } from '@angular/core';
import { AdminScopes, AdminUser, UserService } from '@app/shared/services/user.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { LoggerService } from '@app/shared/services/logger.service';
import { BannerNotificationsService } from '@app/shared/services/banner-notifications.service';
import { MTX_DRAWER_DATA, MtxDrawerRef } from '@ng-matero/extensions/drawer';
import { ClaimsService } from '@app/shared/services/claims.service';
import { SupportClaimStatus } from '@app/shared/constants';

@Component({
  selector: 'app-update-claim-status',
  templateUrl: './update-claim-status.component.html',
})
export class UpdateClaimStatusComponent implements OnInit {
  public claim: any;
  public adminUser: AdminUser;
  public form: FormGroup;
  public isLoading: boolean = true;
  public statusDropdown: { id: string; text: string }[] = [];

  constructor(
    private userService: UserService,
    private formBuilder: FormBuilder,
    private logger: LoggerService,
    private bannerNotificationsService: BannerNotificationsService,
    public drawerRef: MtxDrawerRef<UpdateClaimStatusComponent>,
    private claimsService: ClaimsService,
    @Inject(MTX_DRAWER_DATA)
    public data: {
      claim: any;
      adminUser: AdminUser;
    }
  ) {}

  ngOnInit() {
    if (
      this.data &&
      this.data.claim &&
      this.data.adminUser &&
      this.userService.hasAuthScope(this.data.adminUser, [AdminScopes.VIEW_CLAIMS])
    ) {
      this.claim = this.data.claim;
      this.adminUser = this.data.adminUser;
      this.createForm();
    } else {
      this.dismissDrawer();
    }
  }

  public dismissDrawer(successful: boolean = false): void {
    this.drawerRef.dismiss(successful);
  }

  public submitForm(): void {
    this.claimsService.updateClaim(this.claim.id, this.form.value).subscribe(
      (res) => {
        this.bannerNotificationsService.success('Claim was updated successfully');
        this.logger.log('Update claim success', res);
        this.dismissDrawer(true);
      },
      (err) => {
        this.bannerNotificationsService.error('Updating claim failed');
        this.logger.error('Update claim error', err);
        this.dismissDrawer(false);
      }
    );
  }

  private createForm() {
    // Populate the couriers dropdown
    Object.values(SupportClaimStatus).forEach((claimStatus) => {
      this.statusDropdown.push({
        id: claimStatus,
        text: claimStatus,
      });
    });

    this.form = this.formBuilder.group({
      status: [this.claim.status, Validators.required],
    });
    this.isLoading = false;
  }
}
