<ng-container *ngIf='!loading, else isLoading'>
  <div class='row'>
    <div class='column--12 ticket author-user'>
      <mat-card class='mat-elevation-z0 bg--light-green border-none no-shadow'>
        <mat-card-content>
          <div class='pb--sm' [innerHtml]='supportTicket.description'></div>
          <div class='row' *ngIf='supportTicket.attachments && supportTicket.attachments.length > 0'>
            <div *ngFor='let attachment of supportTicket.attachments' class='column--4'>
              <app-ticket-attachment [ticketAttachment]='attachment'></app-ticket-attachment>
            </div>
          </div>
        </mat-card-content>
      </mat-card>
      <div class='text--sm text--gray-optional mt--sm'>Submitted {{ supportTicket.timeAgo }}</div>
    </div>
  </div>

  <ng-container *ngIf='supportTicket.conversations && supportTicket.conversations.length > 0'>
    <div class='row mt--md' *ngFor="let conversation of supportTicket.conversations">
      <div class='column--12 ticket {{ conversation.authorClassName }}'>
        <mat-card class='mat-elevation-z0 {{ conversation.cardClass }} border-none no-shadow'>
          <mat-card-content>
            <div class='pb--sm' [innerHtml]='conversation.body'></div>
            <div class='row' *ngIf='conversation.attachments && conversation.attachments.length > 0'>
              <div *ngFor='let attachment of conversation.attachments' class='column--4'>
                <app-ticket-attachment [ticketAttachment]='attachment'></app-ticket-attachment>
              </div>
            </div>
          </mat-card-content>
        </mat-card>
        <div class='text--sm text--gray-optional mt--sm'>Submitted {{ conversation.timeAgo }}</div>
      </div>
    </div>
  </ng-container>
</ng-container>
<ng-template #isLoading>
  <div class='align-center mt--lg'>
    <app-loading></app-loading>
  </div>
</ng-template>
