import { AngularFireAuthGuard, redirectUnauthorizedTo } from '@angular/fire/compat/auth-guard';
import { RouterModule, Routes } from '@angular/router';
import { ScopeGuard } from '@app/shared/guards/scope.guard';
import { AdminScopes } from '@app/shared/services/user.service';
import { NgModule } from '@angular/core';
import { ComponentsModule } from '@app/shared/components/components.module';
import { CommonModule } from '@angular/common';
import { SharedModule } from '@app/shared/shared.module';
import { ClaimsComponent } from '@app/pages/authenticated/pages/claims/claims.component';
import { ClaimDetailComponent } from '@app/pages/authenticated/pages/claims/components/claim-detail/claim-detail.component';
import { ShipmentsListComponent } from '@app/pages/authenticated/components/shipments-list/shipments-list.component';
import { TicketMessagesComponent } from '@app/pages/authenticated/pages/claims/components/ticket-messages/ticket-messages.component';
import { TicketAttachmentComponent } from '@app/pages/authenticated/pages/claims/components/ticket-attachment/ticket-attachment.component';
import { AddCreditNoteComponent } from '@app/pages/authenticated/pages/claims/components/add-credit-note/add-credit-note.component';
import { FileUploadComponent } from '@app/pages/authenticated/components/file-upload/file-upload.component';
import { UpdateRefundAmountComponent } from '@app/pages/authenticated/pages/claims/components/update-refund-amount/update-refund-amount.component';
import { UpdateClaimStatusComponent } from '@app/pages/authenticated/pages/claims/components/update-claim-status/update-claim-status.component';
import { EditShipmentClaimCostComponent } from '@app/pages/authenticated/pages/claims/components/edit-shipment-claim-cost/edit-shipment-claim-cost.component';
import { UpdateClaimNoteComponent } from '@app/pages/authenticated/pages/claims/components/update-claim-note/update-claim-note.component';

const redirectUnauthorizedToLogin = () => redirectUnauthorizedTo(['login']);
const routes: Routes = [
  {
    path: '',
    canActivate: [AngularFireAuthGuard, ScopeGuard],
    data: { scopes: [AdminScopes.VIEW_CLAIMS] },
    canActivateChild: [ScopeGuard],
    children: [
      {
        path: '',
        component: ClaimsComponent,
        data: { scopes: [AdminScopes.VIEW_CLAIMS], authGuardPipe: redirectUnauthorizedToLogin },
      },
      {
        path: 'ticket/:claimId',
        component: ClaimDetailComponent,
        data: { scopes: [AdminScopes.VIEW_CLAIMS], authGuardPipe: redirectUnauthorizedToLogin },
      },
    ],
  },
];

@NgModule({
  imports: [
    ComponentsModule,
    CommonModule,
    RouterModule.forChild(routes),
    SharedModule,
    ShipmentsListComponent,
    FileUploadComponent,
  ],
  exports: [RouterModule],
  declarations: [
    AddCreditNoteComponent,
    ClaimsComponent,
    ClaimDetailComponent,
    EditShipmentClaimCostComponent,
    TicketAttachmentComponent,
    TicketMessagesComponent,
    UpdateClaimNoteComponent,
    UpdateClaimStatusComponent,
    UpdateRefundAmountComponent,
  ],
})
export class ClaimsModule {}
