<ng-container *ngIf='!loading, else isLoading'>
  <div class='row mb--md'>
    <div class='column--12'>
      <h2 class='mt--none mb--sm'>#{{claim.supportTicketId}}</h2>
      <a [routerLink]="['/claims/']" class='text--primary-green'>< Back to claims</a>
    </div>
  </div>

  <mat-card>
    <mat-card-content>
      <div class='row row-start justify-items-center'>
        <div class='column--2'>
          <div class='semi-bold text--sm text--gray-optional summary-title'>Company</div>
          <div class='text--sm mt--xxs'>{{ claim.companyName }}</div>
        </div>
        <div class='column--2'>
          <div class='semi-bold text--sm text--gray-optional summary-title'>Date created</div>
          <div class='text--sm mt--xxs'>{{ claim.createdAt | timeToString : DATE_FORMAT : defaultTimezone }}</div>
        </div>
        <div class='column--2'>
          <div class='semi-bold text--sm text--gray-optional summary-title'>Claim status</div>
          <div class='text--sm mt--xxs'>
            <mat-chip class="chip-claim-status status-{{claim.statusClass}}">{{ claim.status }}</mat-chip>
          </div>
        </div>
        <div class='column--2'>
          <div class='semi-bold text--sm text--gray-optional summary-title'>Category</div>
          <div class='text--sm mt--xxs'>{{ claim.situation }}</div>
        </div>
        <div class='column--2'>
          <div class='semi-bold text--sm text--gray-optional summary-title'>Case Agent</div>
          <div class='text--sm mt--xxs'>{{ claim.caseAgent }}</div>
        </div>
        <div class='column--2 align-right'>
          <button mat-icon-button [matMenuTriggerFor]="claimMenu" aria-label="Claim actions" color='primary'>
            <mat-icon>more_horiz</mat-icon>
          </button>
          <mat-menu #claimMenu="matMenu">
            <!-- Update Refunded Amount Button -->
            <button mat-menu-item (click)='updateRefundedAmount()' [disabled]='!canEditClaim || !canIssueRefund'>
              <span>Update refunded amount</span>
            </button>
            <!-- Update Claim Status Button -->
            <button mat-menu-item (click)='updateClaimStatus()' [disabled]='!canEditClaim || isFinanceUser'>
              <span>Update claim status</span>
            </button>
            <!-- Update Claim Status Button -->
            <button mat-menu-item (click)='editClaimNote()' [disabled]='!canEditClaim'>
              <span>Update claim notes</span>
            </button>
            <!-- Add Credit Note Button -->
            <button mat-menu-item (click)='upsertCreditNote()' [disabled]='!canEditClaim'>
              <span>Add credit note</span>
            </button>
            <!-- View in FreshDesk Button -->
            <a *ngIf='!isFinanceUser' mat-menu-item href='https://machool.freshdesk.com/a/tickets/{{claim.supportTicketId}}' target='_blank'>
              <span>View ticket in Freshdesk </span>
              <mat-icon class='icon-ex-link primary-green ml--xs mr--none mat-icon-sm mat-menu-icon-after'></mat-icon>
            </a>
            <!-- Delete Button -->
            <button mat-menu-item class='border-top text-danger' (click)="closeClaim()" [disabled]="!canEditClaim || !canCloseClaim">
              <span>Close claim</span>
            </button>
          </mat-menu>
        </div>
      </div>
      <div class='row row-start mt--xs'>
        <div class='column--2'>
          <div class='semi-bold text--sm text--gray-optional summary-title'>Total Requested</div>
          <div class='text--sm mt--xxs'>{{ claim.requestedAmount ? (claim.requestedAmount | currency) : 'N/A' }}</div>
        </div>
        <div class='column--2'>
          <div class='semi-bold text--sm text--gray-optional summary-title'>Machool Billed</div>
          <div class='text--sm mt--xxs'>{{ claim.machoolAmount ? (claim.machoolAmount | currency) : 'N/A' }}</div>
        </div>
        <div class='column--2'>
          <div class='semi-bold text--sm text--gray-optional summary-title'>Courier Credit</div>
          <div class='text--sm mt--xxs'>{{ claim.creditAmount && claim.creditAmount > 0 ? (claim.creditAmount | currency) : 'N/A' }}</div>
        </div>
        <div class='column--2'>
          <div class='semi-bold text--sm text--gray-optional summary-title'>Payable</div>
          <div class='text--sm mt--xxs'>{{ claim.payableAmount ? (claim.payableAmount | currency) : 'N/A' }}</div>
        </div>
        <div class='column--2'>
          <div class='semi-bold text--sm text--gray-optional summary-title'>Payable</div>
          <div class='text--sm mt--xxs'>
            <ng-container *ngIf='!claim.isSurchargeClaim, else payableSurchages'>
              @if (claim.refundMethod === refundMethods.WALLET) {
                {{ claim.refundMethod }}
              }
              @else if (claim.refundMethod === refundMethods.E_TRANSFER) {
              <span class='truncate-container'>
                <span class='text'>{{ claim.refundMethod }} - {{ claim.refundEmail }}</span>
                <span class='icon ml--xxs cursor-pointer' (click)="copyText(claim.refundEmail); $event.stopPropagation();">
                  <mat-icon matListItemIcon class="text-primary-green mat-icon-sm">content_copy</mat-icon>
                </span>
              </span>
              }
              @else {
                'N/A'
              }
            </ng-container>
            <ng-template #payableSurchages>
              Original payment method
            </ng-template>
          </div>
        </div>
      </div>
    </mat-card-content>
  </mat-card>

  <mat-card class='mt--md'>
    <mat-card-content>
      <mat-accordion class="expansion-list">
        <mat-expansion-panel *ngIf="(claim.note || '').length > 0" hideToggle class="mat-elevation-z0">
          <mat-expansion-panel-header>
            <mat-panel-title>
              <mat-icon class='icon-plus expand-panel-icon'></mat-icon>
              <mat-icon class='icon-minus minimize-panel-icon'></mat-icon>
              <div class='expansion-title'>Notes / Refund Links</div>
            </mat-panel-title>
          </mat-expansion-panel-header>

          <div [innerHTML]='claim.note'></div>
        </mat-expansion-panel>

        <mat-expansion-panel *ngIf='(claim.shipments || []).length > 0' hideToggle class="mat-elevation-z0">
          <mat-expansion-panel-header>
            <mat-panel-title>
              <mat-icon class='icon-plus expand-panel-icon'></mat-icon>
              <mat-icon class='icon-minus minimize-panel-icon'></mat-icon>
              <div class='expansion-title'>Shipments</div>
              <mat-icon
                *ngIf="shipmentsWithoutCosts"
                [matTooltip]="'Missing shipment costs'"
                matTooltipPosition="above"
                class="icon-warn mat-icon-sm text--warn ml--xs cursor-pointer"
              ></mat-icon>
            </mat-panel-title>
          </mat-expansion-panel-header>

          <shipments-list
            [shipments]='claim.shipments'
            [totalShipments]='claim.shipments.length'
            [user]='user'
            [noCard]='true'
            [isFinanceListing]='false'
            [showClaimCostWithTax]='true'
            [hideUselessPagination]='true'
            [canEditClaimCost]='canEditClaim'
            (editClaimCostEvent)='editClaimCost($event)'
          ></shipments-list>
        </mat-expansion-panel>

        <mat-expansion-panel hideToggle class="mat-elevation-z0">
          <mat-expansion-panel-header>
            <mat-panel-title>
              <mat-icon class='icon-plus expand-panel-icon'></mat-icon>
              <mat-icon class='icon-minus minimize-panel-icon'></mat-icon>
              <div class='expansion-title'>Messages</div>
            </mat-panel-title>
          </mat-expansion-panel-header>

          <app-ticket-messages [supportTicket]='claim.supportTicket'></app-ticket-messages>
        </mat-expansion-panel>
        <mat-expansion-panel hideToggle class="mat-elevation-z0" *ngIf='claim.creditNotes && claim.creditNotes.length > 0'>
          <mat-expansion-panel-header>
            <mat-panel-title>
              <mat-icon class='icon-plus expand-panel-icon'></mat-icon>
              <mat-icon class='icon-minus minimize-panel-icon'></mat-icon>
              <div class='expansion-title'>Credit Notes</div>
            </mat-panel-title>
          </mat-expansion-panel-header>

          <table
            class='bg--accent-gray'
            mat-table
            matSort
            (matSortChange)="sortData($event)"
            [dataSource]="sortedCreditNotes"
          >
            <ng-container matColumnDef="provider">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>Issuer</th>
              <td mat-cell *matCellDef="let element">{{ element.providerName }}</td>
            </ng-container>
            <ng-container matColumnDef="creditNoteName">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>Name</th>
              <td mat-cell *matCellDef="let element">
                <span *ngIf='element.creditAmount'>{{ element.creditNoteName }}</span>
              </td>
            </ng-container>
            <ng-container matColumnDef="createdAt">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>Date</th>
              <td mat-cell *matCellDef="let element">{{ element.createdAt | timeToString : SHORT_DATE_FORMAT : defaultTimezone }}</td>
            </ng-container>
            <ng-container matColumnDef="creditAmount">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>Credit Amount</th>
              <td mat-cell *matCellDef="let element">
                <span *ngIf='element.creditAmount'>{{ element.creditAmount | currency }}</span>
              </td>
            </ng-container>
            <ng-container matColumnDef="action">
              <th mat-header-cell *matHeaderCellDef mat-sort-header></th>
              <td mat-cell *matCellDef="let element" class='align-right'>
                <button mat-icon-button color='primary' class='mat-small-icon' (click)='downloadNote(element)' title='Download Credit Note'>
                  <i class='icon-download text--base'></i>
                </button>
                <button mat-icon-button color='primary' class='mat-small-icon' (click)='upsertCreditNote(element)' title='Edit Credit Note' [disabled]='!canEditClaim'>
                  <i class='icon-edit text--base'></i>
                </button>
                <button mat-icon-button class='mat-small-icon text--danger' (click)='removeCreditNote(element)' title='Remove Credit Note' [disabled]='!canEditClaim'>
                  <mat-icon class='icon-bin'></mat-icon>
                </button>
              </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="creditNoteColumns"></tr>
            <tr mat-row *matRowDef="let myRowData; columns: creditNoteColumns"></tr>
          </table>
        </mat-expansion-panel>
      </mat-accordion>
    </mat-card-content>
  </mat-card>
</ng-container>
<ng-template #isLoading>
  <div class='align-center mt--lg'>
    <app-loading></app-loading>
  </div>
</ng-template>
