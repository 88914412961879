import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { catchError, filter, map, Observable, switchMap, tap, throwError } from 'rxjs';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { SessionStorageService } from 'ngx-webstorage';
import { Router } from '@angular/router';
import firebase from 'firebase/compat';
import * as _ from 'lodash';
import User = firebase.User;
import { HelpersService } from './helpers.service';
import { ErrorMessages } from '@app/shared/constants';

//TODO: Move this machool-js
export enum AdminScopes {
  VIEW_DASHBOARD = 'view.dashboard',
  VIEW_USERS = 'view.users',
  VIEW_USERS_LOGIN = 'view.users.login',
  VIEW_USERS_SETTINGS = 'view.users.settings',
  VIEW_USERS_SETTINGS_BILLING = 'view.users.settings.billing',
  VIEW_USERS_SETTINGS_COMPANY_CHARGES = 'view.users.settings.company_charges',
  VIEW_USERS_SETTINGS_TIER = 'view.users.settings.tier',
  VIEW_USERS_SETTINGS_PAYMENT_TYPE = 'view.users.settings.payment_type',
  VIEW_USERS_SETTINGS_MACHOOL_PICKUP = 'view.users.settings.machool_pickup',
  VIEW_USERS_SETTINGS_NOTES = 'view.users.settings.notes',
  VIEW_USERS_SETTINGS_SALES = 'view.users.settings.sales',
  VIEW_USERS_SETTINGS_COURIERS = 'view.users.settings.couriers',
  VIEW_USERS_SETTINGS_COURIER_MODIFIERS = 'view.users.settings.courier_modifiers',
  VIEW_USERS_SETTINGS_DYNAMIC_PRICING = 'view.users.settings.dynamic_pricing',
  VIEW_USERS_SETTINGS_WALLET_TRANSACTIONS = 'view.users.settings.wallet_transactions',
  MODIFY_DASHBOARD = 'modify.dashboard',
  MODIFY_DASHBOARD_ZONES = 'modify.dashboard.zones',
  MODIFY_DASHBOARD_CARDS = 'modify.dashboard.cards',
  MODIFY_USERS = 'modify.users',
  MODIFY_USERS_SETTINGS = 'modify.users.settings',
  MODIFY_USERS_SETTINGS_BILLING = 'modify.users.settings.billing',
  MODIFY_USERS_SETTINGS_COMPANY_CHARGES = 'modify.users.settings.company_charges',
  MODIFY_USERS_SETTINGS_BILLING_INVOICES = 'modify.users.settings.billing.invoices',
  MODIFY_USERS_SETTINGS_TIER = 'modify.users.settings.tier',
  MODIFY_USERS_SETTINGS_PAYMENT_TYPE = 'modify.users.settings.payment_type',
  MODIFY_USERS_SETTINGS_MACHOOL_PICKUP = 'modify.users.settings.machool_pickup',
  MODIFY_USERS_SETTINGS_NOTES = 'modify.users.settings.notes',
  MODIFY_USERS_SETTINGS_SALES = 'modify.users.settings.sales',
  MODIFY_USERS_SETTINGS_COURIER_MODIFIERS = 'modify.users.settings.courier_modifiers',
  MODIFY_USERS_SETTINGS_DYNAMIC_PRICING = 'modify.users.settings.dynamic_pricing',
  MODIFY_USERS_SETTINGS_WALLET_TRANSACTIONS = 'modify.users.settings.wallet_transactions',
  VIEW_SHIPMENTS = 'view.shipments',
  VIEW_SHIPMENTS_DETAILS = 'view.shipments.details',
  VIEW_RECONCILIATION = 'view.reconciliation',
  VIEW_SERVICE_BUILDER = 'view.service_builder',
  VIEW_NOTIFICATIONS = 'view.notifications',
  VIEW_CANCELLATIONS = 'view.cancellations',
  VIEW_INSURANCE_CLAIMS = 'view.insurance_claims',
  VIEW_REPORTS = 'view.reports',
  VIEW_INVOICES = 'view.invoices',
  VIEW_INVOICES_LIST = 'view.invoices.list',
  VIEW_INVOICES_CUSTOMERS = 'view.invoices.customers',
  MODIFY_INVOICES_LIST = 'modify.invoices.list',
  MODIFY_INVOICES_CUSTOMERS = 'modify.invoices.customers',
  VIEW_MACHOOL_REPORTS = 'view.reports.machool',
  VIEW_REPORTS_CARDS = 'view.reports.cards',
  VIEW_REPORTS_CARD_DATA = 'view.reports.card_data',
  VIEW_REPORTS_TOP_REVENUE = 'view.reports.top_revenue',
  VIEW_ACC_REPORTS = 'view.reports.acc',
  VIEW_API_KEYS = 'view.api_keys',
  MODIFY_SHIPMENTS = 'modify.shipments',
  MODIFY_SHIPMENTS_RETURN_LABEL = 'modify.shipments.return_label',
  MODIFY_RECONCILIATION = 'modify.reconciliation',
  MODIFY_SERVICE_BUILDER = 'modify.service_builder',
  MODIFY_NOTIFICATIONS = 'modify.notifications',
  MODIFY_CANCELLATIONS = 'modify.cancellations',
  MODIFY_INSURANCE_CLAIMS = 'modify.insurance_claims',
  MODIFY_REPORTS = 'modify.reports',
  MODIFY_API_KEYS = 'modify.api_keys',
  VIEW_MANIFESTS = 'view.manifests',
  MODIFY_MANIFESTS = 'modify.manifests',
  VIEW_COURIERS = 'view.couriers',
  MODIFY_COURIERS = 'modify.couriers',
  LIMIT_RIVO = 'limit.rivo',
  VIEW_UNBILLABLE_SHIPMENTS = 'view.unbillable_shipments',
  VIEW_UNKNOWN_TRACKING = 'view.unknown_tracking',
  MODIFY_UNKNOWN_TRACKING = 'modify.unknown_tracking',
  VIEW_PLATFORMS = 'view.platforms',
  MODIFY_PLATFORMS = 'modify.platforms',
  MODIFY_STATUS = 'modify.status',
  VIEW_FINANCE = 'view.finance',
  VIEW_PERMISSIONS = 'view.permissions',
  MODIFY_PERMISSIONS = 'modify.permissions',
  VIEW_CLAIMS = 'view.claims',
  VIEW_CLAIMS_FINANCE = 'view.claims.finance',
  MODIFY_CLAIMS = 'modify.claims',
}

export interface AdminUser {
  uid: string;
  firstName: string;
  lastName: string;
  email: string;
  role: string;
  scopes: AdminScopes[];
}
@Injectable({ providedIn: 'root' })
export class UserService {
  constructor(
    private firebaseAuth: AngularFireAuth,
    private helperService: HelpersService,
    private router: Router,
    private sessionStorageService: SessionStorageService,
    private http: HttpClient
  ) {}

  public getCurrentUser(): AdminUser {
    return this.sessionStorageService.retrieve('currentUser');
  }

  public storeCurrentUser(currentUser: AdminUser): void {
    this.sessionStorageService.store('currentUser', currentUser);
  }

  public getAuthenticatedUser(scopes?: AdminScopes[]): Observable<any> {
    let fbUser: User;
    return this.firebaseAuth.user.pipe(
      // Get the currently authenticated user from Firebase
      filter((user: any) => user !== null),
      tap((user: User) => (fbUser = user)),
      // Get the idToken for that user
      switchMap((user: User) =>
        user ? user.getIdToken(true) : throwError(() => new Error(ErrorMessages.UNAUTHORIZED))
      ),
      // Get the currently authenticated user from app-services
      switchMap((idToken: string) => {
        this.sessionStorageService.store('idToken', idToken);
        return this.getCurrentAccount(fbUser.uid, idToken, scopes);
      }),
      catchError((error: any) => throwError((err) => new Error(error)))
    );
  }

  public getAdminUsers(): Observable<any> {
    return this.http.get<AdminUser[]>(`${environment.APP_SERVICES_URL}/admin/admin_users`).pipe(
      map((res) => {
        return res;
      }),
      catchError((error: any) => throwError(() => ErrorMessages.UNEXPECTED))
    );
  }

  public updateAdminUsers(id, scopes): Observable<any> {
    return this.http.put<AdminUser>(`${environment.APP_SERVICES_URL}/admin/admin_users/${id}`, { scopes }).pipe(
      map((res) => {
        return res;
      }),
      catchError((error: any) => throwError(() => ErrorMessages.UNEXPECTED))
    );
  }

  public hasAuthScope(adminUser: AdminUser, scopes?: AdminScopes[]): boolean {
    return scopes.every((scope: AdminScopes) => adminUser.scopes.includes(scope));
    if (!scopes || (scopes && scopes.length === 0)) return true;
    return true;
  }

  public getFromAll(query: {
    email: string;
    firstName?: string;
    lastName?: string;
    companyName?: string;
    role?: 'U' | 'A' | 'O' | 'M';
  }): Observable<any> {
    return this.http
      .get(`${environment.APP_SERVICES_URL}/admin/users/search`, {
        params: query,
      })
      .pipe(
        map((res: any) => {
          return res;
        }),
        catchError((error: any) => throwError(() => ErrorMessages.UNEXPECTED))
      );
  }

  private getCurrentAccount(fbUid: any, fbIdToken: string, scopes?: AdminScopes[]): Observable<AdminUser> {
    const params = scopes && scopes.length > 0 ? { scope: scopes } : {};
    return this.http
      .get<AdminUser>(`${environment.APP_SERVICES_URL}/admin/current`, {
        headers: {
          uid: fbUid,
          authorization: 'Bearer ' + fbIdToken,
        },
        params,
      })
      .pipe(
        map((adminUser: AdminUser) => {
          this.storeCurrentUser(_.extend(adminUser, { idToken: fbIdToken }));
          return adminUser;
        }),
        catchError((error: any) => throwError(() => ErrorMessages.UNEXPECTED))
      );
  }

  public verifyUser(params: { email: string }): Observable<any> {
    return this.http.post(`${environment.APP_SERVICES_URL}/admin/users/verify_account`, params).pipe(
      map((res: any) => {
        return res;
      }),
      catchError((error: any) => throwError(() => ErrorMessages.UNEXPECTED))
    );
  }
}
