import { Component, Inject, OnInit } from '@angular/core';
import { AdminScopes, AdminUser, UserService } from '@app/shared/services/user.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { LoggerService } from '@app/shared/services/logger.service';
import { BannerNotificationsService } from '@app/shared/services/banner-notifications.service';
import { MTX_DRAWER_DATA, MtxDrawerRef } from '@ng-matero/extensions/drawer';
import { ClaimsService } from '@app/shared/services/claims.service';
import { ProvidersService } from '@app/shared/services/providers.service';
import { ShippingProviders } from '@app/shared/constants';
import { HelpersService } from '@app/shared/services/helpers.service';

@Component({
  selector: 'app-add-credit-note',
  templateUrl: './add-credit-note.component.html',
})
export class AddCreditNoteComponent implements OnInit {
  public claim: any;
  public creditNote: any;
  public adminUser: AdminUser;
  public form: FormGroup;
  public isLoading: boolean = true;
  public isEditing: boolean = false;
  public creditNoteFile: any;

  public readonly issuerOptions = [];

  constructor(
    private userService: UserService,
    private providersService: ProvidersService,
    private formBuilder: FormBuilder,
    private logger: LoggerService,
    private bannerNotificationsService: BannerNotificationsService,
    private helpersService: HelpersService,
    public drawerRef: MtxDrawerRef<AddCreditNoteComponent>,
    private claimsService: ClaimsService,
    @Inject(MTX_DRAWER_DATA)
    public data: {
      claim: any;
      adminUser: AdminUser;
      creditNote?: any;
    }
  ) {}

  ngOnInit() {
    if (
      this.data &&
      this.data.claim &&
      this.data.adminUser &&
      this.userService.hasAuthScope(this.data.adminUser, [AdminScopes.VIEW_CLAIMS])
    ) {
      this.claim = this.data.claim;
      this.adminUser = this.data.adminUser;
      this.creditNote = this.data.creditNote;
      this.isEditing = this.creditNote?.id;
      this.createForm();
    } else {
      this.dismissDrawer();
    }
  }

  public submitForm() {
    const formValues = this.form.value;
    const params = {
      claimId: this.claim.id,
      creditAmount: formValues.creditAmount,
      provider: formValues.provider,
    };
    const formData = new FormData();
    formData.append('createClaimCreditNoteDto', JSON.stringify(params));

    // Attach credit note file
    if (this.creditNoteFile) {
      formData.append('creditNote', this.creditNoteFile, this.creditNoteFile.name);
    }
    if (this.isEditing) {
      this.claimsService.updateCreditNote(this.creditNote.id, formData).subscribe(
        (res) => {
          this.bannerNotificationsService.success('Credit note was saved successfully');
          this.logger.log('Add credit note success', res);
          this.dismissDrawer(true);
        },
        (err) => {
          this.bannerNotificationsService.error('Saving credit note failed');
          this.logger.error('Add credit note error', err);
          this.dismissDrawer(false);
        }
      );
    } else {
      this.claimsService.createCreditNote(formData).subscribe(
        (res) => {
          this.bannerNotificationsService.success('Credit note was saved successfully');
          this.logger.log('Add credit note success', res);
          this.dismissDrawer(true);
        },
        (err) => {
          this.bannerNotificationsService.error('Saving credit note failed');
          this.logger.error('Add credit note error', err);
          this.dismissDrawer(false);
        }
      );
    }
  }

  public updateUploadedFile(file: any): void {
    this.creditNoteFile = file;
  }

  public dismissDrawer(successful: boolean = false): void {
    this.drawerRef.dismiss(successful);
  }

  private createForm() {
    // Populate the credit note issuer dropdown
    // Machool Goodwill
    this.issuerOptions.push({
      groupName: 'Machool',
      options: [
        {
          id: 'Machool',
          text: 'Machool Goodwill Credit',
        },
      ],
    });
    // 3rd Party Insurance
    const allInsuranceProviders: any[] = this.providersService.getInsuranceProvidersList();
    const insuranceOptions = [];
    allInsuranceProviders.forEach((provider) => {
      insuranceOptions.push({
        id: provider.enum,
        text: provider.name,
      });
    });
    this.issuerOptions.push({
      groupName: 'Insurance',
      options: insuranceOptions,
    });
    // Couriers
    const allProviders: any[] = (this.providersService.getProvidersList() || []).sort((a, b) =>
      a.provider.localeCompare(b.provider)
    );
    const courierOptions = [];
    allProviders.forEach((provider) => {
      if ([ShippingProviders.RIVO].includes(provider.enum)) {
        courierOptions.push({
          id: provider.enum,
          text: this.helpersService.convertShipmentProviderToString(provider.enum, undefined, true),
        });
      } else if (![ShippingProviders.INTELCOM].includes(provider.enum)) {
        courierOptions.push({
          id: provider.enum,
          text: provider.name,
        });
      }
    });
    this.issuerOptions.push({
      groupName: 'Couriers',
      options: courierOptions,
    });

    this.form = this.formBuilder.group({
      provider: [this.creditNote?.provider || '', Validators.required],
      creditAmount: [
        this.helpersService.roundNumber(parseFloat(`${this.creditNote?.creditAmount || 0}`)),
        Validators.required,
      ],
      file: [''],
    });
    if (!this.isEditing) {
      this.form.get('file').setValidators(Validators.required);
    }
    this.isLoading = false;
  }
}
