<div class='row row--valign-center'>
  <div class='column--8'>
    <h3>Add / edit claim notes</h3>
  </div>
  <div class='column--4 align-right'>
    <button mat-icon-button color='primary' (click)='dismissDrawer()'><mat-icon>close</mat-icon></button>
  </div>
</div>
<form [formGroup]="form">
  <div class='row'>
    <div class='column--12'>
      <div class='full-width'>
        <label class='mb--sm'>Additional note</label>
        <quill-editor class="w-100" name="note" [modules]='wysiwygModules' formControlName='note'></quill-editor>
      </div>
    </div>
  </div>
</form>

<div class='row mt--md'>
  <div class='column--6'>
    <button mat-flat-button color='accent' (click)='dismissDrawer()'>Cancel</button>
  </div>
  <div class='column--6 align-right'>
    <button mat-flat-button color='primary' (click)='submitForm()' [disabled]='form.invalid'>Save</button>
  </div>
</div>
