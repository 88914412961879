<ng-container *ngIf='!loading, else isLoading'>
  <div class='row row-start'>
    <div class='column--12'>
      <form [formGroup]="searchForm">
        <mat-card class='mb--md'>
          <mat-card-content class='pb--none'>
            <div class='row'>
              <div class='column--12 column-md--4'>
                <mat-form-field class='full-width'>
                  <mat-label>Company Name</mat-label>
                  <input matInput formControlName='companyName' name='companyName' value="">
                </mat-form-field>
              </div>
              <div class='column--12 column-md--4'>
                <mat-form-field class='full-width'>
                  <mat-label>Ticket number</mat-label>
                  <input matInput formControlName='ticketId' name='ticketId' value="">
                </mat-form-field>
              </div>
              <div class='column--12 column-md--4'>
                <mat-form-field class='full-width'>
                  <mat-label>Claim Status</mat-label>
                  <mat-select formControlName="status" name='status'>
                    <mat-option *ngFor='let statusItem of statusItems' value="{{ statusItem.id }}">{{ statusItem.text }}</mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>
            <div class="row">
              <div class='column--12 align-right mb--sm'>
                <button mat-button color='primary' type='button' (click)='reset()' class='mr--md' [disabled]="searchForm.invalid || loading">Clear all</button>
                <button mat-flat-button color='primary' type='submit' (click)='search()' [disabled]="searchForm.invalid || loading">Search</button>
              </div>
            </div>
          </mat-card-content>
        </mat-card>
      </form>
    </div>
  </div>
  <ng-container *ngIf='!loadingClaims, else isLoadingClaims'>
    <mat-card>
      <mat-card-content>
        <ng-container *ngIf='paginatedClaims.length > 0, else noClaims'>
          <table
            mat-table
            matSort
            (matSortChange)="sortData($event)"
            [dataSource]="paginatedClaims"
          >
            <ng-container matColumnDef="companyName">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>Company Name</th>
              <td mat-cell *matCellDef="let element">
                <span *ngIf='element.companyName'>{{ element.companyName }}</span>
              </td>
            </ng-container>
            <ng-container matColumnDef="supportTicketId">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>Ticket Number</th>
              <td mat-cell *matCellDef="let element">
                <button
                  *ngIf='element.supportTicketId'
                  mat-button
                  color='primary'
                  class='mdc-small-button text--base align-left'
                  (click)="gotoClaim(element.id)"
                >
                  {{ element.supportTicketId }}
                </button>
              </td>
            </ng-container>
            <ng-container matColumnDef="createdAt">
              <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by date">Date of Claim</th>
              <td mat-cell *matCellDef="let element">{{ element.createdAt | timeToString : DATE_FORMAT : defaultTimezone }}</td>
            </ng-container>
            <ng-container matColumnDef="isSurchargeClaim">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>Category</th>
              <td mat-cell *matCellDef="let element">
                <span>{{ element.isSurchargeClaim ? 'Surcharges' : 'Claim' }}</span>
              </td>
            </ng-container>
            <ng-container matColumnDef="requestedAmount">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>Req Amt.</th>
              <td mat-cell *matCellDef="let element">
                <span *ngIf='element.requestedAmount'>{{ element.requestedAmount | currency }}</span>
              </td>
            </ng-container>
            <ng-container matColumnDef="refundedAmount">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>Refd. Amt.</th>
              <td mat-cell *matCellDef="let element">
                {{ element.payableAmount ? (element.payableAmount | currency) : 'N/A' }}
              </td>
            </ng-container>
            <ng-container matColumnDef="status">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>Status</th>
              <td mat-cell *matCellDef="let element">
                <mat-chip class="chip-claim-status status-{{element.statusClass}}">{{ element.status }}</mat-chip>
              </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let myRowData; columns: displayedColumns"></tr>
          </table>

          <mat-paginator
            (page)="pageChanged($event)"
            [length]='claimsCount'
            [pageSize]="itemsPerPage"
            [pageSizeOptions]="itemsPerPageOptions"
            [pageIndex]='currentPage'>
          </mat-paginator>
        </ng-container>
        <ng-template #noClaims>
          <div class='row justify-content-center mt--lg'>
            <div class='column--12 column-md--4 align-center'>
              <img src='{{ CDN_URL }}/admin/images/empty_animated.svg' width='250' />
              <p>No claims found</p>
            </div>
          </div>
        </ng-template>
      </mat-card-content>
    </mat-card>
  </ng-container>
  <ng-template #isLoadingClaims>
    <div class='align-center mt--lg'>
      <app-loading></app-loading>
    </div>
  </ng-template>
</ng-container>
<ng-template #isLoading>
  <div class='align-center mt--lg'>
    <app-loading></app-loading>
  </div>
</ng-template>
