<div class='row row--valign-center'>
  <div class='column--8'>
    <h3>{{ isEditing ? 'Update' : 'Add' }} Credit Note</h3>
  </div>
  <div class='column--4 align-right'>
    <button mat-icon-button color='primary' (click)='dismissDrawer()'><mat-icon>close</mat-icon></button>
  </div>
</div>
<form [formGroup]="form">
  <div class='row'>
    <div class='column--12'>
      <mat-form-field class='full-width'>
        <mat-label>Issuer</mat-label>
        <mat-select formControlName="provider" name='provider' placeholder='Select a courier'>
          <mat-optgroup *ngFor='let optionGroup of issuerOptions' [label]='optionGroup.groupName'>
            <mat-option *ngFor='let option of optionGroup.options' value="{{ option.id }}">{{ option.text }}</mat-option>
          </mat-optgroup>
        </mat-select>
      </mat-form-field>
    </div>
  </div>
  <div class='row'>
    <div class='column--12'>
      <mat-form-field class='full-width'>
        <mat-label>Credit Amount</mat-label>
        <mat-icon matIconPrefix>attach_money</mat-icon>
        <input matInput formControlName='creditAmount' name='creditAmount' value="">
      </mat-form-field>
    </div>
  </div>
  <div class='row'>
    <div class='column--12'>
      <label class='mb--sm'>Credit Note</label>
      <file-upload
        [fileFormControl]="form.get('file')"
        [buttonLabel]="isEditing ? 'Update credit note' : 'Import credit note'"
        (uploadedFileEvent)='updateUploadedFile($event)'
      ></file-upload>
    </div>
  </div>
</form>

<div class='row mt--md'>
  <div class='column--6'>
    <button mat-flat-button color='accent' (click)='dismissDrawer()'>Cancel</button>
  </div>
  <div class='column--6 align-right'>
    <button mat-flat-button color='primary' (click)='submitForm()' [disabled]='form.invalid'>Save</button>
  </div>
</div>
