import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-ticket-attachment',
  templateUrl: './ticket-attachment.component.html',
})
export class TicketAttachmentComponent {
  @Input() ticketAttachment: any;
  constructor() {}
}
