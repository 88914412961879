<div class='row row--valign-center'>
  <div class='column--8'>
    <h3>Update claim status</h3>
  </div>
  <div class='column--4 align-right'>
    <button mat-icon-button color='primary' (click)='dismissDrawer()'><mat-icon>close</mat-icon></button>
  </div>
</div>
<form [formGroup]="form">
  <div class='row'>
    <div class='column--12'>
      <mat-form-field class='full-width'>
        <mat-label>Courier</mat-label>
        <mat-select formControlName="status" name='status' placeholder='Select a status'>
          <mat-option *ngFor='let option of statusDropdown' value="{{ option.id }}">{{ option.text }}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>
</form>

<div class='row mt--md'>
  <div class='column--6'>
    <button mat-flat-button color='accent' (click)='dismissDrawer()'>Cancel</button>
  </div>
  <div class='column--6 align-right'>
    <button mat-flat-button color='primary' (click)='submitForm()' [disabled]='form.invalid'>Save</button>
  </div>
</div>
