import {
  Component,
  CUSTOM_ELEMENTS_SCHEMA,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import { ComponentsModule } from '@app/shared/components/components.module';
import { CommonModule, NgIf } from '@angular/common';
import { MatButton, MatIconButton } from '@angular/material/button';
import { MatIcon } from '@angular/material/icon';
import { SharedModule } from '@app/shared/shared.module';
import { AbstractControl, FormControl } from '@angular/forms';

@Component({
  selector: 'file-upload',
  standalone: true,
  encapsulation: ViewEncapsulation.None,
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  templateUrl: './file-upload.component.html',
  imports: [ComponentsModule, CommonModule, MatButton, MatIcon, MatIconButton, NgIf, SharedModule],
})
export class FileUploadComponent implements OnInit {
  @Input() fileFormControl: AbstractControl | null = null;
  @Input() buttonLabel: string = 'Upload file';
  @Input() buttonType: 'accent' | 'primary' = 'accent';
  @Output() uploadedFileEvent: EventEmitter<any> = new EventEmitter();

  public uploadedFile: any;

  constructor() {}

  ngOnInit() {}

  public fileControl(): FormControl {
    return this.fileFormControl as FormControl;
  }

  public onFileChanged(event: any) {
    if (event.target.files && event.target.files.length) {
      const file = event.target.files[0];
      if (file) {
        this.uploadedFile = file;
        this.uploadedFileEvent.emit(this.uploadedFile);
      }
    }
  }

  public removeFile() {
    this.uploadedFile = undefined;
    this.fileFormControl.patchValue(undefined);
    this.uploadedFileEvent.emit(this.uploadedFile);
  }
}
