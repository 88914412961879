<div class='row row--valign-center'>
  <div class='column--8'>
    <h3>Update Shipment Costs</h3>
  </div>
  <div class='column--4 align-right'>
    <button mat-icon-button color='primary' (click)='dismissDrawer()'><mat-icon>close</mat-icon></button>
  </div>
</div>

<div class='row mt--md mb--xl'>
  <div class='column--12'>
    <mat-accordion class="expansion-list">
      <mat-expansion-panel hideToggle class="mat-elevation-z0" *ngIf='adminChargeDetailsStringified && adminChargeDetailsStringified.length > 0'>
        <mat-expansion-panel-header class='px--none py--sm'>
          <mat-panel-title>
            <mat-icon class='icon-plus expand-panel-icon'></mat-icon>
            <mat-icon class='icon-minus minimize-panel-icon'></mat-icon>
            <div class='expansion-title'>Admin charge details</div>
          </mat-panel-title>
        </mat-expansion-panel-header>

        <pre class='text--sm' notranslate>{{ adminChargeDetailsStringified }}</pre>
      </mat-expansion-panel>
      <mat-expansion-panel hideToggle class="mat-elevation-z0" *ngIf='ediInvoiceStringified && ediInvoiceStringified.length > 0'>
        <mat-expansion-panel-header class='px--none py--sm'>
          <mat-panel-title>
            <mat-icon class='icon-plus expand-panel-icon'></mat-icon>
            <mat-icon class='icon-minus minimize-panel-icon'></mat-icon>
            <div class='expansion-title'>EDI details</div>
          </mat-panel-title>
        </mat-expansion-panel-header>

        <pre class='text--sm' notranslate>{{ ediInvoiceStringified }}</pre>
      </mat-expansion-panel>
    </mat-accordion>
  </div>
</div>

<form [formGroup]="form">
  <div class='row' *ngIf='isClaimTicket'>
    <div class='column--12'>
      <mat-form-field class='full-width'>
        <mat-label>Total requested</mat-label>
        <mat-icon matIconPrefix>attach_money</mat-icon>
        <input matInput formControlName='requestedAmount' name='requestedAmount' placeholder="0.00">
      </mat-form-field>
    </div>
  </div>
  <div class='row'>
    <div class='column--12'>
      <mat-form-field class='full-width'>
        <mat-label>Customer Cost</mat-label>
        <mat-icon matIconPrefix>attach_money</mat-icon>
        <input matInput formControlName='customerAmount' name='customerAmount' placeholder="0.00">
      </mat-form-field>
    </div>
  </div>
  <div class='row'>
    <div class='column--12'>
      <mat-form-field class='full-width'>
        <mat-label>Machool Cost</mat-label>
        <mat-icon matIconPrefix>attach_money</mat-icon>
        <input matInput formControlName='machoolAmount' name='machoolAmount' placeholder="0.00">
      </mat-form-field>
    </div>
  </div>
  <div class='row'>
    <div class='column--12'>
      <mat-form-field class='full-width'>
        <mat-label>Credit Amount</mat-label>
        <mat-icon matIconPrefix>attach_money</mat-icon>
        <input matInput formControlName='creditAmount' name='creditAmount' placeholder="0.00">
      </mat-form-field>
    </div>
  </div>
  <div class='row mb--md' *ngIf='isClaimTicket'>
    <div class='column--12'>
      <mat-checkbox formControlName="includeShipmentCost">Credit includes Machool Cost</mat-checkbox>
    </div>
  </div>
  <div class='row'>
    <div class='column--12'>
      <mat-form-field class='full-width'>
        <mat-label>Payable Amount</mat-label>
        <mat-icon matIconPrefix>attach_money</mat-icon>
        <input matInput formControlName='payableAmount' name='payableAmount' placeholder="0.00">
      </mat-form-field>
    </div>
  </div>
</form>

<div class='row mt--md'>
  <div class='column--6'>
    <button mat-flat-button color='accent' (click)='dismissDrawer()'>Cancel</button>
  </div>
  <div class='column--6 align-right'>
    <button mat-flat-button color='primary' (click)='submitForm()' [disabled]='form.invalid'>Save</button>
  </div>
</div>
