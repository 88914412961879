import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { AdminScopes, AdminUser, UserService } from '@app/shared/services/user.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { LoggerService } from '@app/shared/services/logger.service';
import { BannerNotificationsService } from '@app/shared/services/banner-notifications.service';
import { HelpersService } from '@app/shared/services/helpers.service';
import { MTX_DRAWER_DATA, MtxDrawerRef } from '@ng-matero/extensions/drawer';
import { ClaimsService } from '@app/shared/services/claims.service';
import { Animations } from '@app/shared/constants';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-edit-shipment-claim-cost',
  templateUrl: './edit-shipment-claim-cost.component.html',
  animations: [Animations.ExpandAnimation],
})
export class EditShipmentClaimCostComponent implements OnInit, OnDestroy {
  public claim: any;
  public adminUser: AdminUser;
  public form: FormGroup;
  public isLoading: boolean = true;
  public shipment: any;
  public adminChargeDetailsStringified: string;
  public ediInvoiceStringified: string;
  public isClaimTicket: boolean = false;
  private currentValues: {
    customerAmount: number;
    machoolAmount: number;
    creditAmount: number;
    payableAmount: number;
  };
  private formSubscription: Subscription;

  constructor(
    private userService: UserService,
    private formBuilder: FormBuilder,
    private logger: LoggerService,
    private bannerNotificationsService: BannerNotificationsService,
    private helpersService: HelpersService,
    public drawerRef: MtxDrawerRef<EditShipmentClaimCostComponent>,
    private claimsService: ClaimsService,
    @Inject(MTX_DRAWER_DATA)
    public data: {
      claim: any;
      adminUser: AdminUser;
      shipment: any;
    }
  ) {}

  ngOnInit() {
    if (
      this.data &&
      this.data.claim &&
      this.data.adminUser &&
      this.data.shipment &&
      this.userService.hasAuthScope(this.data.adminUser, [AdminScopes.VIEW_CLAIMS])
    ) {
      this.claim = this.data.claim;
      this.adminUser = this.data.adminUser;
      this.shipment = this.data.shipment;
      this.adminChargeDetailsStringified = JSON.stringify(this.shipment.adminChargeDetails, undefined, 3);
      if (this.shipment.ediInvoice) {
        const ediInvoiceParsed =
          typeof this.shipment.ediInvoice === 'string'
            ? JSON.parse(this.shipment.ediInvoice)
            : this.shipment.ediInvoice;
        this.ediInvoiceStringified = JSON.stringify(ediInvoiceParsed, undefined, 2);
      }
      this.isClaimTicket = !this.claim.isSurchargeClaim;
      this.createForm();
    } else {
      this.dismissDrawer();
    }
  }

  ngOnDestroy() {
    this.formSubscription.unsubscribe();
  }

  public dismissDrawer(successful: boolean = false): void {
    this.drawerRef.dismiss(successful);
  }

  public submitForm(): void {
    const formValues = this.form.value;
    const params = {
      customerAmount: formValues.customerAmount,
      machoolAmount: formValues.machoolAmount,
      creditAmount: formValues.creditAmount,
      payableAmount: formValues.payableAmount,
    };

    this.claimsService.updateClaimShipment(this.claim.id, this.shipment.id, params).subscribe(
      (res) => {
        this.bannerNotificationsService.success('Shipment claim amounts updated successfully');
        this.logger.log('Update claim shipment success', res);
        this.dismissDrawer(true);
      },
      (err) => {
        this.bannerNotificationsService.error('Updating shipment claim amounts failed');
        this.logger.error('Update claim shipment error', err);
        this.dismissDrawer(false);
      }
    );
  }

  private updatedPayableAmount(value: {
    customerAmount: number;
    machoolAmount: number;
    creditAmount: number;
    payableAmount: number;
    includeShipmentCost: boolean;
  }) {
    if (value !== this.currentValues && value.payableAmount === this.currentValues.payableAmount) {
      const creditAmount = parseFloat(`${value.creditAmount}`);
      const customerCost = parseFloat(`${value.customerAmount}`);
      const machoolCost = parseFloat(`${value.machoolAmount}`);
      let payableAmount: number = 0;

      if (this.isClaimTicket) {
        const requestedAmount = parseFloat(`${this.claim.requestedAmount}`);
        const calculatedCustomerCost = customerCost + requestedAmount;
        const calculatedCreditAmount =
          value.includeShipmentCost && creditAmount > 0 ? creditAmount - machoolCost + customerCost : creditAmount;
        payableAmount = calculatedCreditAmount;

        if (calculatedCreditAmount > calculatedCustomerCost) {
          payableAmount = calculatedCustomerCost;
        }
      } else {
        payableAmount = (creditAmount * customerCost) / machoolCost;
        if (creditAmount >= machoolCost) {
          payableAmount = customerCost;
        }
      }

      value.payableAmount =
        !isNaN(payableAmount) && typeof payableAmount === 'number' ? Math.round(payableAmount * 100) / 100 : 0;
      this.form.patchValue(
        {
          payableAmount: value.payableAmount,
        },
        { emitEvent: false }
      );
      this.currentValues = value;
    }
  }

  private createForm() {
    this.form = this.formBuilder.group({
      requestedAmount: [
        this.claim.requestedAmount > 0 ? this.helpersService.roundNumber(this.claim.requestedAmount) : 0,
      ],
      customerAmount: [
        this.shipment.claimCustomerAmount > 0 ? this.helpersService.roundNumber(this.shipment.claimCustomerAmount) : '',
        Validators.required,
      ],
      machoolAmount: [
        this.shipment.claimMachoolAmount > 0 ? this.helpersService.roundNumber(this.shipment.claimMachoolAmount) : '',
        Validators.required,
      ],
      creditAmount: [
        this.shipment.claimCreditAmount > 0 ? this.helpersService.roundNumber(this.shipment.claimCreditAmount) : 0,
      ],
      payableAmount: [
        this.shipment.claimPaymentAmount > 0 ? this.helpersService.roundNumber(this.shipment.claimPaymentAmount) : 0,
      ],
      includeShipmentCost: [false],
    });
    this.form.get('requestedAmount').disable();
    this.currentValues = this.form.value;
    this.formSubscription = this.form.valueChanges.subscribe((value) => {
      this.updatedPayableAmount(value);
    });
    this.isLoading = false;
  }
}
