import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { catchError, map, Observable, take, throwError } from 'rxjs';
import { environment } from '../../../environments/environment';
import { ErrorMessages, InsuranceProviders, ShippingProviders, SupportClaimStatus } from '@app/shared/constants';
import { HelpersService } from '@app/shared/services/helpers.service';

@Injectable({ providedIn: 'root' })
export class ClaimsService {
  constructor(private http: HttpClient, private helpersService: HelpersService) {}

  public get(id: number): Observable<any> {
    return this.http
      .get(`${environment.APP_SERVICES_URL}/admin/claims/${id}`, {
        observe: 'response',
      })
      .pipe(
        take(1),
        map((res: any) => {
          return res.body;
        }),
        catchError((error: any) => throwError(error.error || ErrorMessages.UNEXPECTED))
      );
  }

  public getFromAll(query: {
    companyName?: string;
    ticketNumber?: number;
    status?: SupportClaimStatus;
  }): Observable<any> {
    return this.http
      .get(`${environment.APP_SERVICES_URL}/admin/claims`, {
        params: query,
      })
      .pipe(
        map((res: any) => {
          return res;
        }),
        catchError((error: any) => throwError(() => ErrorMessages.UNEXPECTED))
      );
  }

  public updateClaim(id: number, params: any): Observable<any> {
    return this.http.put(`${environment.APP_SERVICES_URL}/admin/claims/${id}`, params).pipe(
      take(1),
      map((res: any) => {
        return res;
      }),
      catchError((error: any) => throwError(error.error || ErrorMessages.UNEXPECTED))
    );
  }

  public updateClaimShipment(claimId: number, shipmentId: number, params: any): Observable<any> {
    return this.http.put(`${environment.APP_SERVICES_URL}/admin/claims/${claimId}/shipment/${shipmentId}`, params).pipe(
      take(1),
      map((res: any) => {
        return res;
      }),
      catchError((error: any) => throwError(error.error || ErrorMessages.UNEXPECTED))
    );
  }

  public getCreditNote(id: number): Observable<any> {
    return this.http
      .get(`${environment.APP_SERVICES_URL}/admin/claims/credit-notes/${id}`, {
        observe: 'response',
      })
      .pipe(
        take(1),
        map((res: any) => {
          return res.body;
        }),
        catchError((error: any) => throwError(error.error || ErrorMessages.UNEXPECTED))
      );
  }

  public deleteCreditNote(id: number): Observable<any> {
    return this.http
      .delete(`${environment.APP_SERVICES_URL}/admin/claims/credit-notes/${id}`, {
        observe: 'response',
      })
      .pipe(
        take(1),
        map((res: any) => {
          return res;
        }),
        catchError((error: any) => throwError(error.error || ErrorMessages.UNEXPECTED))
      );
  }

  public createCreditNote(formData: any): any {
    return this.http.post(`${environment.APP_SERVICES_URL}/admin/claims/credit-notes`, formData).pipe(
      map((res: any) => res),
      catchError((error: any) => throwError(error.error || ErrorMessages.UNEXPECTED))
    );
  }

  public updateCreditNote(creditNoteId: number, formData: any): any {
    return this.http.post(`${environment.APP_SERVICES_URL}/admin/claims/credit-notes/${creditNoteId}`, formData).pipe(
      map((res: any) => res),
      catchError((error: any) => throwError(error.error || ErrorMessages.UNEXPECTED))
    );
  }

  public convertCreditNoteProvider(provider: string): string {
    if (provider === 'Machool') {
      return 'Machool Goodwill Credit';
    } else if (Object.values(InsuranceProviders).includes(provider as InsuranceProviders)) {
      return this.helpersService.convertInsuranceProviderToString(provider as InsuranceProviders);
    } else {
      return this.helpersService.convertShipmentProviderToString(provider as ShippingProviders);
    }
  }
}
